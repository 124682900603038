<template>
  <div class="row">
    <div class="col-lg-3">
      <section class="card card-inverse card-success">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="'/static/img/hotspot.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('hotspot.hotspotWizard') }}</div>
          <div v-if="currentStepIndex === '1'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step1') }}</p>
            <p>{{ $t('hotspot.wizard1Title') }}</p>
          </div>
          <div v-if="currentStepIndex === '2'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step2') }}</p>
            <p>{{ $t('hotspot.wizard2Title') }}</p>
          </div>
          <div v-if="currentStepIndex === '3'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step3') }}</p>
            <p>{{ $t('hotspot.wizard3Title') }}</p>
          </div>
          <div v-if="currentStepIndex === '4'">
            <p class="text-muted text-uppercase font-weight-bold">{{ $t('general.step4') }}</p>
            <p>{{ $t('hotspot.wizard4Title') }}</p>
          </div>

          <div class="progress progress-white progress-xs mb-1">
            <div
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
              class="progress-bar"
              :style="{ width: progressWidth + '%', transition: 'all 0.2s' }"
            ></div>
          </div>
        </div>
      </section>
    </div>
    <div class="col-lg-9"><router-view></router-view></div>
  </div>
</template>

<script>
export default {
  name: 'HotspotWizard',
  computed: {
    currentStepIndex() {
      return this.$route.name.replace('hotspot-step-', '');
    },
    progressWidth() {
      let width;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'hotspot-step-1':
          width = 25;
          break;
        case 'hotspot-step-2':
          width = 50;
          break;
        case 'hotspot-step-3':
          width = 75;
          break;
        case 'hotspot-step-4':
          width = 100;
          break;
        default:
          width = 100;
      }
      return width;
    }
  },
  methods: {
    nextStep() {
      let nextStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'hotspot-step-1':
          nextStep = 'hotspot-step-2';
          break;
        case 'hotspot-step-2':
          nextStep = 'hotspot-step-3';
          break;
        case 'hotspot-step-3':
          nextStep = 'hotspot-step-4';
          break;
        case 'hotspot-step-4':
          nextStep = 'Hotspot';
          break;
        default:
          nextStep = 'hotspot-wizard';
      }
      this.$router.push({ name: nextStep });
    },
    prevStep() {
      let prevStep;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'hotspot-step-1':
          prevStep = 'Hotspot';
          break;
        case 'hotspot-step-2':
          prevStep = 'hotspot-step-1';
          break;
        case 'hotspot-step-3':
          prevStep = 'hotspot-step-2';
          break;
        case 'hotspot-step-4':
          prevStep = 'hotspot-step-3';
          break;
        default:
          prevStep = 'hotspot-wizard';
      }
      this.$router.push({ name: prevStep });
    }
  }
};
</script>
<style lang="scss">
.wizard-wlan-icon {
  height: 80px;
}
</style>
